<script setup lang="ts">
import type { VehicleResponse } from '~/types/vehicle';
import type { VehicleInformation, VehiclePlateData, VehicleGenericInformations } from '~/types/interfaces';
import { useAppStore } from "@/store/useAppStore";
import { useStorage } from '@vueuse/core'
import { useUserStore } from '~/store/useUserStore'
import { useGraphicSearchStore } from '~/store/useGraphicSearchStore';
import type { LinkedGenArt } from '~/store/interfaces/graphicStoreInterfaces';

const appStore = useAppStore()
const { links } = useAppConfig()
const userStore = useUserStore();
const graphicSearchStore = useGraphicSearchStore();

const { addToast } = useAppToast()
const { isMultipleVehicleSelectOpen, vehiclesPlateData, searchVehicleFrom, vehicleTypeList } = useDashboard()
const sessionQuote = useStorage("quote", {}, sessionStorage);
const { cookies, localStorage } = useRuntimeConfig().public
const vehicleInformations: Ref<VehicleInformation> = useCookie(cookies.vehicleInformation)
const vehiclePlateData: Ref<VehiclePlateData> = useCookie(cookies.vehiclePlateData)
const vehicleGenericData: Ref<VehicleGenericInformations> = useCookie(cookies.vehicleGenericInformations)
const linkedGenArtsCookies: Ref<LinkedGenArt[] | null> = useCookie(localStorage.linkedGenArts);

const headerValues = ref([{
    value: 'Type',
    textAlign: 'left'
}, {
    value: 'Numéro TecDoc',
    textAlign: 'center'
}, {
    value: 'Code moteur',
    textAlign: 'center'
}, {
    value: 'Capacité',
    textAlign: 'center'
}, {
    value: 'Alimentation',
    textAlign: 'center'
}]);

const selectVehicle = async (kType: number, plateNumber?: string) => {
    try {
        const getCarByPlateResponse = await $fetch<VehicleResponse>('/api/siv/getCarByKtype', { query: {
            immat: plateNumber,
            userEmail: userStore.user.customerEmail,
            Ktype: kType
        }})

        if(getCarByPlateResponse.success !== "1") throw new Error(`[${getCarByPlateResponse.err_code}] ${getCarByPlateResponse.msg}`)
    
        const singleVehicle = getCarByPlateResponse?.carPlateDatas.length ? getCarByPlateResponse.carPlateDatas.filter((vehicle) => vehicle.KType === kType && vehicle.numberPlate === plateNumber) : []
        const genericVehicle = getCarByPlateResponse.carGenericDatas.filter((vehicle) => vehicle.KType === kType)
    
        appStore.infoCar.actualKType = kType.toString()
        appStore.infoCar.actualImmat = plateNumber
        appStore.infoCar.carGenericDatas = genericVehicle
        appStore.infoCar.carPlateDatas = singleVehicle

        if(singleVehicle.length > 0) {
            appStore.infoCar.actualVin = singleVehicle[0].vin
            vehiclePlateData.value = singleVehicle[0] 
        }
        
        vehicleGenericData.value = genericVehicle[0]
        linkedGenArtsCookies.value = null

        graphicSearchStore.carGenArts = []
        graphicSearchStore.graphicsHtml = {}
        graphicSearchStore.graphicStep = 'vehicle'
        vehicleInformations.value = {
            actualKType: appStore.infoCar.actualKType,
            actualImmat: appStore.infoCar.actualImmat,
            actualVin: appStore.infoCar.actualVin,
            lastKType: "",
            lastImmat: ""
        }
        if(searchVehicleFrom.value !== 'selectKilometers') {
            sessionQuote.value = null
            await navigateTo(links.catalog)
        } else {
            isMultipleVehicleSelectOpen.value = false
        }
    } catch(err: any) {
        console.error(err)
        addToast("Une erreur est survenue lors de la récupération du véhicule.", 'error')
    }
}

</script>

<template>
    <ElementsModal :isOpen="isMultipleVehicleSelectOpen" @close="isMultipleVehicleSelectOpen = false" title="Validez votre véhicule">
        <template #content>
            <ElementsSearchNumberPlate :isLabel="false" class="flex items-center flex-col justify-center mb-3.5"/>

            <BoxTable class="box-dashbord table" :isActions="true">
                <template #headerTable>
                    <BoxItemHeaderTable v-for="(headerItem, index) in headerValues" :key="index" :title="headerItem.value" :textAlign="headerItem.textAlign" />
                </template>
                <template #mainTable v-if="vehiclesPlateData.length">
                    <BoxItemRowTable v-for="(vehicle, index) in vehiclesPlateData" :key="index">
                        <BoxItemBodyTable :value="vehicle.carName" textAlign="left" class="w-500"/>
                        <BoxItemBodyTable :value="vehicle.tecDocModNr" textAlign="center"/>
                        <BoxItemBodyTable :value="vehicle.engineCode" textAlign="center"/>
                        <BoxItemBodyTable :value="vehicle.engineCapacity" textAlign="center"/>
                        <BoxItemBodyTable :value="`${vehicle.power} Kw`" textAlign="center"/>                        
                        <BoxItemActionTable 
                            class="table-actions-primary" 
                            :isShowAction="searchVehicleFrom !== 'selectKilometers'" 
                            @show="selectVehicle(vehicle.KType, vehicle.numberPlate)" 
                            :isAddAction="searchVehicleFrom === 'selectKilometers'"
                            @add="selectVehicle(vehicle.KType, vehicle.numberPlate)" 
                        />
                    </BoxItemRowTable>
                </template>

                <template #mainTable v-if="vehicleTypeList.length">
                    <BoxItemRowTable v-for="(vehicle, index) in vehicleTypeList" :key="index">
                        <BoxItemBodyTable :value="vehicle.typeName" textAlign="left" class="w-500"/>
                        <BoxItemBodyTable value="" textAlign="center"/>
                        <BoxItemBodyTable :value="vehicle.typeDetails.find(type => type.addInfoKeyId === -4)?.addInfoKeyValue" textAlign="center"/>
                        <BoxItemBodyTable :value="vehicle.typeDetails.find(type => type.addInfoKeyId === 6)?.addInfoKeyValue" textAlign="center"/>
                        <BoxItemBodyTable 
                            :value="`${vehicle.typeDetails.find(type => type.addInfoKeyId === 5)?.addInfoKeyValue} ${vehicle.typeDetails.find(type => type.addInfoKeyId === 5)?.addInfoKeyName}`" 
                            textAlign="center"
                        />                        
                        <BoxItemActionTable 
                            class="table-actions-primary" 
                            :isShowAction="searchVehicleFrom !== 'selectKilometers'" 
                            @show="selectVehicle(vehicle.tcdTypeId)" 
                            :isAddAction="searchVehicleFrom === 'selectKilometers'"
                            @add="selectVehicle(vehicle.tcdTypeId)" 
                        />
                    </BoxItemRowTable>
                </template>
            </BoxTable>
        </template>
    </ElementsModal>
</template>